import { jsx as _jsx } from "react/jsx-runtime";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import App from './components/app';
import { theme } from './theme/theme';
var container = document.getElementById('reactele');
var link = new HttpLink({ uri: "".concat(process.env.API_URL, "/graphql") });
var cache = new InMemoryCache().restore(window.__APOLLO_STATE__);
var client = new ApolloClient({ link: link, cache: cache });
hydrateRoot(container, (_jsx(BrowserRouter, { basename: "/", children: _jsx(ApolloProvider, { client: client, children: _jsx(ThemeProvider, { theme: theme, children: _jsx(App, {}) }) }) })));
